function setCookie(){
    document.cookie = "feedback_sent=true";
};

function getCookie(){
    let responded;
    (document.cookie == "feedback_sent=true") ? responded = true : responded = false;
    return responded  
};

export {
    setCookie,
    getCookie
}