const configs = {
    firebaseConfig: {
        apiKey: "AIzaSyAdnxY8LYCUmA8__vMLNIMaoT3MxPyj9Fw",
        authDomain: "hospital-feedback-e2370.firebaseapp.com",
        projectId: "hospital-feedback-e2370",  
        databaseURL: "https://hospital-feedback-e2370-default-rtdb.asia-southeast1.firebasedatabase.app" ,
        storageBucket: "hospital-feedback-e2370.appspot.com",
        messagingSenderId: "988291677764",  
        appId: "1:988291677764:web:f527ff8f31cbd2061a4611",  
        measurementId: "G-Z295XQTEFM"
    },
    FIREBASE_RDB_DIR: 'param-hospital/feedback/' ,
    ORG_NAME: 'Param Hospital',
    GOOGLE_REVIEW_LINK: 'https://g.page/ParamHospital/review',
    EN_ORG_CAT: 'hospital' ,
    HI_ORG_CAT: 'अस्पताल',
    MR_ORG_CAT: 'रुग्णालय'
};

export default configs;